import React, {useEffect, useState} from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function EventSearch() {
  const [eventName, changeEventName] = useState("");
  const [assigneeName, changeAssigneeName] = useState("null");
  const [dateFilter, changeDateFilter] = useState("fromtoday");
  const [sort, setSort] = useState("event_date");

  const events = useStoreState(state => state.events.rows);
  const selectEvent = useStoreActions(state => state.events.loadEventDataThunk);
  const currentEvent = useStoreState(state => state.events.selectedEvent);

  const [{ customMinDate, customMaxDate }, setCustomDate] = useState({
    customMinDate: null,
    customMaxDate: null
  });

  const [filteredEvents, setFilteredEvents] = useState(events);

  const loadEvents = useStoreActions(action => action.events.loadEventsThunk);

  useEffect(() => {
    const interval = setInterval(() => {
      loadEvents();
    }, 10000);
    return () => clearInterval(interval);
  }, [loadEvents]);

  useEffect(() => {
    let updatedEvents = [...events];

    const includeInEvent = (ev, keyword) => {
      return (
        ev.name.toLowerCase().includes(keyword) ||
        ev.venue_name.toLowerCase().includes(keyword) ||
        ev.venue_city.toLowerCase().includes(keyword)
      );
    };

    if (eventName.length >= 2) {
      updatedEvents = updatedEvents.filter(ev =>
        eventName.split(" ").every(keyword =>
          includeInEvent(ev, keyword.toLowerCase())
        )
      );
    }

    if (assigneeName !== "null") {

      if (assigneeName === "unassigned") {
        updatedEvents = updatedEvents.filter(ev => !ev.assignee);
      } else {
      updatedEvents = updatedEvents.filter(ev =>
        ev.assignee?.toLowerCase().includes(assigneeName.toLowerCase())
      );
      }
    }

    if (dateFilter === "custom") {
      const momentMin = moment(customMinDate).startOf("day");
      const momentMax = moment(customMaxDate).endOf("day");

      updatedEvents = updatedEvents.filter(ev => {
        const evDate = moment(ev.event_date);
        return evDate.isAfter(momentMin) && evDate.isBefore(momentMax);
      });
    } else {
      let dateBefore;
      let dateAfter;

      switch (dateFilter) {
        case "fromtoday":
          dateBefore = moment().add(10, "years").endOf("day");
          dateAfter = moment().startOf("day");
          break;
        case "last7":
          dateBefore = moment().endOf("day");
          dateAfter = moment().subtract(7, "days").startOf("day");
          break;
        case "yesterday":
          dateBefore = moment().subtract(1, "day").endOf("day");
          dateAfter = moment().subtract(1, "day").startOf("day");
          break;
        case "today":
          dateBefore = moment().endOf("day");
          dateAfter = moment().startOf("day");
          break;
        case "tomorrow":
          dateBefore = moment().add(1, "day").endOf("day");
          dateAfter = moment().add(1, "day").startOf("day");
          break;
        case "next3":
          dateBefore = moment().add(3, "days").endOf("day");
          dateAfter = moment().startOf("day");
          break;
        case "next7":
          dateBefore = moment().add(7, "days").endOf("day");
          dateAfter = moment().startOf("day");
          break;
        case "next14":
          dateBefore = moment().add(14, "days").endOf("day");
          dateAfter = moment().startOf("day");
          break;
        case "next30":
          dateBefore = moment().add(30, "days").endOf("day");
          dateAfter = moment().startOf("day");
          break;
        case "thismonth":
          dateBefore = moment().endOf("month").endOf("day");
          dateAfter = moment().startOf("month").startOf("day");
          break;
        case "thisyear":
          dateBefore = moment().endOf("year").endOf("day");
          dateAfter = moment().startOf("year").startOf("day");
          break;
        default:
          throw new Error(`Filter ${dateFilter} does not exist`);
      }

      updatedEvents = updatedEvents.filter(ev => {
        const evDate = moment(ev.event_date);
        return evDate.isAfter(dateAfter) && evDate.isBefore(dateBefore);
      });
    }

    switch (sort) {
      case "event_date":
        updatedEvents.sort((a, b) =>
          moment(a.event_date).isBefore(moment(b.event_date)) ? -1 : 1
        );
        break;
      case "date_added":
        updatedEvents.sort((a, b) =>
          moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1
        );
        break;
      case "total_cost":
        updatedEvents.sort((a, b) => (a.cost > b.cost ? -1 : 1));
        break;
      default:
        throw new Error(`Sort ${sort} does not exist`);
    }

    setFilteredEvents(updatedEvents);
  }, [eventName, dateFilter, customMinDate, customMaxDate, sort, events, assigneeName]);

  return (
    <>
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        <h4>Search Event</h4>

        <div
          style={{ marginTop: 5 }}
          className="kt-input-icon kt-input-icon--left"
        >
          <input
            type="text"
            className="form-control"
            placeholder="Artist name"
            name={"event_name"}
            value={eventName}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                if (filteredEvents.length === 1) {
                  selectEvent(filteredEvents[0]);
                }
              }
            }}
            onChange={evt => {
              changeEventName(evt.target.value);
            }}
            id="artistSearch"
          />
          <span className="kt-input-icon__icon kt-input-icon__icon--left">
            <span>
              <i className="fa fa-search"></i>
            </span>
          </span>
        </div>

        <div style={{ marginTop: 5 }}>
          <select
            className="form-control"
            name="assignee_name"
            value={assigneeName}
            onChange={evt => {
              changeAssigneeName(evt.target.value);
            }}
            id="assigneeSearch"
          >
            <option value="null">All</option>
            <option value="unassigned">Unassigned</option>
            <option value="George Andrew Menegon">George Andrew Menegon</option>
            <option value="Marie-Pier Lavallee">Marie-Pier Lavallee</option>
            <option value="David Sbrugnera">David Sbrugnera</option>
            <option value="Guillaume Provost">Guillaume Provost</option>
          </select>
        </div>

        <div style={{ marginTop: 10 }}>
          <select
            onChange={evt => changeDateFilter(evt.target.value)}
            name="date"
            className="form-control"
          >
            <option value="fromtoday">From Today</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last7">Last 7 days</option>
            <option value="tomorrow">Tomorrow</option>
            <option value="next3">Next 3 Days</option>
            <option value="next7">Next 7 Days</option>
            <option value="next14">Next 14 Days</option>
            <option value="next30">Next 30 Days</option>
            <option value="thismonth">This Month</option>
            <option value="thisyear">This Year</option>
            <option value="custom">Custom Range</option>
          </select>

          {dateFilter === "custom" && (
            <div className={"input-group"} style={{ marginTop: 10 }}>
              <DatePicker
                className="form-control"
                placeholderText={"From"}
                dateFormat="yyyy/MM/dd"
                selected={customMinDate}
                onChange={date => {
                  setCustomDate({ customMinDate: date, customMaxDate });
                }}
              />
              <DatePicker
                className="form-control"
                placeholderText={"To"}
                dateFormat="yyyy/MM/dd"
                selected={customMaxDate}
                onChange={date => {
                  setCustomDate({ customMaxDate: date, customMinDate });
                }}
              />
            </div>
          )}
        </div>

        <div style={{ marginTop: 10 }}>
          <h5>Sort</h5>

          <select
            onChange={e => setSort(e.target.value)}
            name="sort"
            className="form-control"
            value={sort}
          >
            <option value="event_date">Event Date</option>
            <option value="date_added">Date added</option>
            <option value="total_cost">Total Cost</option>
          </select>

        </div>
      </div>

      <div style={{ marginTop: 20 }} id="events-tab">
        <h4 style={{ marginLeft: 10 }}>
          Events
          {events.length > 0 && (
            <span>
              {" ("}
              {filteredEvents.length})
            </span>
          )}
        </h4>

        <div id="event-list">
          <div id="event-list-inside">
            <div
              style={{
                overflow: "auto",
                height: "calc(100vh - 430px)",
                overflowY: "scroll",
                overflowX: "hidden"
              }}
            >
              {events.length <= 0 && (
                <div style={{ position: "absolute", left: 200, top: 100 }}>
                  <div className="kt-spinner kt-spinner--md kt-spinner--info"></div>
                </div>
              )}

              {(assigneeName !== "null" && assigneeName !== "unassigned" ? filteredEvents : filteredEvents.slice(0, 250))
                  .map(ev => {
                const momentDate = moment(ev.event_date);
                return (
                  <div
                    key={ev.id}
                    onClick={() => {
                      selectEvent(ev);
                    }}
                    className="event-box"
                    style={{
                      width: "100%",
                      padding: 10,
                      backgroundColor:
                        currentEvent && currentEvent.id === ev.id
                          ? "#02a3fb"
                          : "inherit∏"
                    }}
                  >
                    <div className="row">
                      <div className="venue-data col-lg-6">
                        <span
                          style={{ position: "relative", float: "left" }}
                          className="fa fa-map-marker"
                        ></span>
                        <div
                          style={{
                            marginLeft: 15,
                            textTransform: "uppercase",
                            fontSize: 12,
                            fontWeight: "600"
                          }}
                          className="venue-name"
                        >
                          {ev.venue_name}
                        </div>
                        <div style={{ marginLeft: 15 }} className="venue-city">
                          {ev.venue_city}
                        </div>
                      </div>

                      <div
                        className="event-date-data col-lg-6 text-right"
                        style={{ paddingRight: 20 }}
                      >
                        <div className="event-date">
                          <i className="fa fa-calendar" />{" "}
                          {momentDate.format("ddd, MMM Do YYYY")}
                        </div>
                        <div className="event-time">
                          {momentDate.format("h:mm A")}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: 15,
                        fontWeight: "bold",
                        fontSize: 14,
                        textAlign: "center"
                      }}
                      className="event-name"
                    >
                      {ev.name}
                    </div>

                    <div style={{ marginTop: 8, display: "flex", justifyContent: "space-between" }}>
                      <small>
                        {ev.listing_count} | {ev.quantity} @ ${ev.cost}{" "}
                      </small>

                      <small>
                        {moment(ev.created_at).fromNow()}
                      </small>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventSearch;
