import React, {useEffect, useState} from 'react';
import { ReactComponent as SHLogo } from "../../../assets/images/stubhub-icon.svg";
import { ReactComponent as SGLogo } from "../../../assets/images/seatgeek-icon.svg";
import { ReactComponent as TMLogo } from "../../../assets/images/ticketmaster-icon.svg";
import {useStoreActions, useStoreState} from "easy-peasy";

const MarketplacesIcons = ({ tmId: currentTmId, sgId, shId, vividId }) => {
  const selectedEvent = useStoreState(state => state.events.selectedEvent);

  const [tmId, setTmId] = useState(" ");

  useEffect(() => {
    if (currentTmId) setTmId(currentTmId);
  }, [currentTmId]);

  const matchEvent = useStoreActions(
    actions => actions.events.matchEventThunk
  );

  const onSubmit = () => {
    selectedEvent.ticketmaster_id = tmId;
    matchEvent(selectedEvent);
  };

  return (
    <div style={{marginRight: 20, display: "flex", alignItems: "center"}}>
      {vividId && (
        <span style={{cursor: "pointer", margin: 'auto', whiteSpace: "nowrap", marginRight: 10}}
              onClick={() => navigator.clipboard.writeText(vividId).then()}
        >
                <span className="kt-font-brand">{vividId}</span>
                <i className="fa fa-clipboard" aria-hidden="true" style={{marginLeft: 5}}></i>
            </span>
      )}
      <div style={{marginRight: 15, marginTop: 4}}>
        <div>
          <a href={tmId ? `https://bowman.plessinc.com/event/${tmId}` : '#'} target={tmId ? `_blank` : ''}
             rel="noopener noreferrer"
             style={{
               cursor: tmId ? '' : 'default',
             }}>
            <div style={{
              position: 'relative',
              left: 5,
              padding: 4,
              fontWeight: 700,
              fontSize: 12,
              lineHeight: 0.8,
            }}>
                        <span style={{
                          color: tmId ? 'inherit' : 'gray'

                        }}>
                            Bow<br/>Man
                        </span>
            </div>
          </a>
        </div>
      </div>

      <div style={{marginRight: 15, marginTop: 4}}>
        {sgId ?
          <a href={`https://seatgeek.com/e/events/${sgId}`} target="_blank" rel="noopener noreferrer">
            <SGLogo
              style={{height: 27, width: 32}}
            />
          </a>
          :
          <SGLogo
            style={{height: 27, width: 32, filter: "grayscale(100%"}}
          />
        }
      </div>
      <div style={{marginRight: 15}}>
        {shId ? <a href={`https://www.stubhub.com/event/${shId}`} target="_blank" rel="noopener noreferrer">
            <SHLogo
              style={{height: 37, width: 40}}
            />
          </a>
          : <SHLogo
            style={{height: 37, width: 40, filter: "grayscale(100%)"}}
          />}
      </div>

      <div style={{display: "flex", alignItems: "center", gap: 5}}>
        <div>
          {tmId ?
            <a href={`https://ticketmaster.com/event/${tmId}`} target="_blank" rel="noopener noreferrer">
              <TMLogo
                style={{height: 27, width: 32}}
              />
            </a>
            :
            <TMLogo
              style={{height: 27, width: 32, filter: "grayscale(100%"}}
            />
          }
        </div>

        <div className="input-group input-group-sm">

          <input type="text" className="form-control"
                  placeholder="TM Id"
                  value={tmId}
                  onChange={e => setTmId(e.target.value)}
          />
          <button className="btn btn-outline-secondary btn-sm"
                  type="button"
                  onClick={onSubmit}
                  disabled={tmId.length < 6}
          >
            <i className="fa fa-check" aria-hidden="true" style={{fontSize: 12}}></i>
          </button>
        </div>


      </div>

    </div>
  );
};

export default MarketplacesIcons;
